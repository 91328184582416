import Loader from "../Loader/loader";
import { Col, Row, Form } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { fetchVaultAccount } from "src/util/API/Global/config";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { getOwnerAddress, getVaultAccountData, pausedGlobalConfig } from "../../util/program/functions";

const HomePage = () => {
	const [data, setData] = useState<any>();
	const [loading, setLoading] = useState(false);
	const [adminAccount, setAdminAccount] = useState("");

	const [isApyEnabled, setIsApyEnabled] = useState(false); // State for APY toggle
	const [isEarlyUnstakeEnabled, setIsEarlyUnstakeEnabled] = useState(false); // State for Early Unstake toggle

	const handleApyToggle = () => setIsApyEnabled(!isApyEnabled);

	const handleEarlyUnstakeToggle = () => setIsEarlyUnstakeEnabled(!isEarlyUnstakeEnabled);

	const wallet = useWallet();
	const { publicKey } = useWallet();

	const { connection } = useConnection();

	const fetchData = useCallback(async () => {
		setLoading(true);
		await fetchVaultAccount()
		const tiersD: any = await getVaultAccountData(connection, wallet);
		setIsApyEnabled(tiersD?.isPuased)
		setIsEarlyUnstakeEnabled(tiersD?.earlyUnstakeEnable)

		setData(tiersD);
		setLoading(false);
		// eslint-disable-next-line
	}, [wallet, connection]);

	const handleClick = async () => {
		setLoading(true);
		try {
			await pausedGlobalConfig(connection, wallet, isApyEnabled, isEarlyUnstakeEnabled);
			await fetchData();
		} catch (error) {
			console.error("Error occurred during update or fetch:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const getownerDetails = async () => {
		const address = await getOwnerAddress(connection, wallet);
		if (address)
			setAdminAccount(address);
	};

	useEffect(() => {
		getownerDetails();
	}, []);

	return (
		<>
			{
				loading ?
					(
						<Loader />
					) : (
						<div className="stake-container main-padding-area">
							<div className="container-width">
								<div className="hello-stake-heading">
									<h1 className="text-white">Hello</h1>
									<p className="text-white text-white-light mb-0">Last activity: <span>{data?.lastTime}</span></p>
								</div>
								<Row className="mb-5">
									<Col lg={4} md={6} className="mb-4">
										<div className="countdown-timer">
											<div className="countdown-timer-info">
												<h3 className="text-white">Total Stakers</h3>
												<p className="text-white text-white-light mb-0">The total number of unique users participating in staking.</p>
											</div>
											<span className="text-green">{data?.stakerCount || 0} </span>
										</div>
									</Col>
									<Col lg={4} md={6} className="mb-4">
										<div className="countdown-timer">
											<div className="countdown-timer-info">
												<h3 className="text-white">Total Tokens Staked</h3>
												<p className="text-white text-white-light mb-0">The aggregate number of tokens currently locked in staking.</p>
											</div>
											<span className="text-green">{data?.totalStaked || 0} </span>
										</div>
									</Col>
									<Col lg={4} md={6} className="mb-4">
										<div className="countdown-timer">
											<div className="countdown-timer-info">
												<h3 className="text-white">Total Claim Reward</h3>
												<p className="text-white text-white-light mb-0">The aggregate number of tokens currently claimed.</p>
											</div>
											<span className="text-green">{data?.totalClaimedRewards || 0} </span>
										</div>
									</Col>
									<Col lg={4} md={6} className="mb-4">
										<div className="countdown-timer">
											<div className="countdown-timer-info">
												<h3 className="text-white">Currently Staked Token</h3>
												<p className="text-white text-white-light mb-0">The aggregate number of tokens currently staked.</p>
											</div>
											<span className="text-green">{data?.currentlyStakedToken || 0} </span>
										</div>
									</Col>
									{
										publicKey?.toBase58() === adminAccount &&
										<Col lg={4} md={6}>
											<div className="countdown-timer h-100">
												<div className="countdown-timer-info counter-timer-content d-flex align-items-center justify-content-between border-0 m-0 p-0 mb-4">
													<h3 className="text-white mb-0">APY Visibility Settings</h3>
													<div className='form-toggle-switch'>
														<Form.Check
															type="switch"
															id="apy-switch"
															checked={isApyEnabled}
															onChange={handleApyToggle}
														/>
													</div>
												</div>
												<div className="countdown-timer-info counter-timer-content d-flex align-items-center justify-content-between border-0 m-0 p-0 mb-4">
													<h3 className="text-white mb-0">Early Unstake Preferences</h3>
													<div className='form-toggle-switch'>
														<Form.Check
															type="switch"
															id="early-unstake-switch"
															checked={isEarlyUnstakeEnabled}
															onChange={handleEarlyUnstakeToggle}
														/>
													</div>
												</div>
												<div className="toggle-home-btn d-flex justify-content-center align-items-center">
													<button
														className="wallet-adapter-button wallet-adapter-button-trigger"
														onClick={handleClick}
													>
														Update
													</button>
												</div>
											</div>
										</Col>
									}
								</Row>
							</div>
						</div>
					)
			}
		</>
	);
};

export default HomePage;