import axiosInstance from "../Handlers";

export const fetchVaultAccount = async () => {
  try {
    const response = await axiosInstance.get("/staker/vault/");
    return response;
  } catch (error) {
    console.error("Error fetching vault account:", error);
    throw error;
  }
};
