import Loader from "../Loader/loader";
import { Col, Image, Row } from "react-bootstrap";
import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { getTokenPrice, initializeorUpdatePrice } from "src/util/program/price";
import infoCircle from "../../assets/images/info-circle.png"
import moment from "moment";

const TokenPrice = () => {

    const [amount, setAmount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [tokenPrice, setTokenPrice] = useState<number>(0)
    const [lastUpdateTime, setLastUpdateTime] = useState<number>(0)

    const wallet = useWallet();
    const { publicKey } = useWallet();
    const { connection } = useConnection();

    const fetchPrice = async () => {
        const { price, lastUpdate } = await getTokenPrice(connection, wallet);
        setTokenPrice(price);
        setAmount(price);
        setLastUpdateTime(lastUpdate)
    };

    const stakeTokenFunc = async () => {
        if (!publicKey) {
            enqueueSnackbar("Please connect your wallet", {
                variant: 'error', autoHideDuration: 6000
            });
            return;
        }

        if (amount < 0) {
            enqueueSnackbar("Amount must be greater than zero", {
                variant: 'error', autoHideDuration: 6000
            });
            return;
        }


        setLoading(true);

        await initializeorUpdatePrice(connection, wallet, amount);
        fetchPrice()
        setAmount(0)
        setLoading(false);
    };

    useEffect(() => {
        fetchPrice()
    }, [publicKey?.toBase58(), connection]);

    return (
        <>
            {
                loading ?
                    (
                        <Loader />
                    ) : (
                        <div className="stake-container main-padding-area">
                            <div className="container-width">
                                <Row>
                                    <Col lg="5" className="mb-lg-0 mb-sm-4 mb-3">
                                        <div className="countdown-timer pb-3">
                                            <div className="countdown-timer-info">
                                                <h3 className="text-white">Update Token Price</h3>
                                                <p className="text-white text-white-light mb-0">Update the token price according to real time token price.</p>
                                            </div>
                                            <input
                                                type="number"
                                                value={amount + ""}
                                                onChange={(e: any) => setAmount(e.target.value)}
                                                placeholder="Enter price"
                                                className="stake-input mb-3"
                                                width={"80%"}
                                                min={0}
                                            />
                                            <button disabled={amount === 0 ? true : false} onClick={stakeTokenFunc} className="btn-green-gradient">
                                                Update Price
                                            </button>
                                        </div>
                                    </Col>

                                    <Col md="3" className="mb-sm-4 mb-3">
                                        <div className="countdown-timer">
                                            <div className="countdown-timer-info">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h3 className="mb-0 text-white">Token Price</h3>
                                                    <div className="info-circle position-relative">
                                                        <Image src={infoCircle} alt="info circle" />
                                                        <div className="stack-tooltip-content countdown-timer position-absolute">
                                                            <div className="countdown-timer-info">
                                                                <h3 className="text-white">Token Price</h3>
                                                            </div>
                                                            <p className="text-white text-white-light">Current Price of token.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="text-white text-white-light">{tokenPrice}</span>
                                        </div>
                                    </Col>
                                    <Col md="3" className="mb-sm-4 mb-3">
                                        <div className="countdown-timer">
                                            <div className="countdown-timer-info">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h3 className="mb-0 text-white">Last Update </h3>
                                                    <div className="info-circle position-relative">
                                                        <Image src={infoCircle} alt="info circle" />
                                                        <div className="stack-tooltip-content countdown-timer position-absolute">
                                                            <div className="countdown-timer-info">
                                                                <h3 className="text-white">Last Update </h3>
                                                            </div>
                                                            <p className="text-white text-white-light">Last time price update.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="text-white text-white-light">{moment.unix(lastUpdateTime).format('MMMM DD, YYYY')}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )
            }
        </>
    );
};

export default TokenPrice;