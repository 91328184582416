import React, { useState, useEffect } from "react";
import moment from "moment";
import { Col, Row, Image } from "react-bootstrap";
import infoCircle from "../../assets/images/info-circle.png"

const RewardsCountdownTimer: React.FC<{
    futureUnixTimestamp: number;
    totalStaked: number;
    claimableTokens: number;
    Staked: number;
}> =
    ({ futureUnixTimestamp, totalStaked, claimableTokens, Staked }) => {
        const [remainingTime, setRemainingTime] = useState("");
        useEffect(() => {
            const updateRemainingTime = () => {
                if (futureUnixTimestamp === 0) {
                    setRemainingTime("Not started Yet");
                }
                const formattedDate = moment.unix(futureUnixTimestamp).format('MMMM DD, YYYY');
                setRemainingTime(
                    formattedDate
                );
            };
            updateRemainingTime();
            const intervalId = setInterval(updateRemainingTime, 1000);
            return () => clearInterval(intervalId);
        }, [futureUnixTimestamp]);

        return (
            <>
                <Row>
                    <Col md="6" className="mb-sm-4 mb-3">
                        <div className="countdown-timer">
                            <div className="countdown-timer-info">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h3 className="mb-0 text-white">Total Rewards</h3>
                                    <div className="info-circle position-relative">
                                        <Image src={infoCircle} alt="info circle" />
                                        <div className="stack-tooltip-content countdown-timer position-absolute">
                                            <div className="countdown-timer-info">
                                                <h3 className="text-white">Total Rewards</h3>
                                            </div>
                                            <p className="text-white text-white-light">The total number of tokens added to reward pool.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="text-white text-white-light">{totalStaked / 1e8}</span>
                        </div>
                    </Col>
                    <Col md="6" className="mb-sm-4 mb-3">
                        <div className="countdown-timer">
                            <div className="countdown-timer-info">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h3 className="mb-0 text-white">Claimed Rewards</h3>
                                    <div className="info-circle position-relative">
                                        <Image src={infoCircle} alt="info circle" />
                                        <div className="stack-tooltip-content countdown-timer position-absolute">
                                            <div className="countdown-timer-info">
                                                <h3 className="text-white">Claimed Rewards</h3>
                                            </div>
                                            <p className="text-white text-white-light">The number of tokens currently claimed from reward pool.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="text-white text-white-light">{claimableTokens / 1e8}</span>
                        </div>
                    </Col>
                    <Col md="6" className="mb-sm-4 mb-3">
                        <div className="countdown-timer">
                            <div className="countdown-timer-info">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h3 className="mb-0 text-white">Last Update Time</h3>
                                    <div className="info-circle position-relative">
                                        <Image src={infoCircle} alt="info circle" />
                                        <div className="stack-tooltip-content countdown-timer position-absolute">
                                            <div className="countdown-timer-info">
                                                <h3 className="text-white">Last Update Time</h3>
                                            </div>
                                            <p className="text-white text-white-light">The last update time of reward pool .</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="text-white text-white-light">{remainingTime}</span>
                        </div>
                    </Col>
                </Row>
            </>
        );
    };

export default RewardsCountdownTimer;
