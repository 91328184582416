import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";

const getBaseURL = () => {
  const baseDomain = process.env.REACT_APP_API_URL || ""
  if (!baseDomain) return "http://localhost:4000/v1/front/"

  if (baseDomain?.endsWith("/")) return baseDomain

  return baseDomain + "/"
}

const baseURL = getBaseURL()

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: baseURL, // Replace with your API's base URL
  timeout: 10000, // Set a timeout for requests (in milliseconds)
  headers: {
    "Content-Type": "application/json",
  },
})

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
)

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data // Simplify response (return only the data)
  },
  (error) => {
    return [];
  }
)

export default axiosInstance
