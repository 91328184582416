import { enqueueSnackbar } from "notistack";
import {
  checkPricePdaAccountExistence,
  parseErrorFromIDL,
} from "./validateAccounts";
import {
  Connection,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import { programInstance } from "./program";
import { PRICEACCOUNT } from "../consts";
import { BN, utils } from "@coral-xyz/anchor";

export const initializePrice = async (
  connection: Connection,
  wallet: any,
  amount: number
) => {
  try {
    const program = programInstance(connection, wallet);

    const [priceAccount] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(PRICEACCOUNT)],
      program.programId
    );
    const amountinpower = Math.round(amount * 1e8);
    let amountBN = new BN(amountinpower.toString());
    const initUserStateTx = await program.methods
      .initializePrice(amountBN)
      .accounts({
        authority: wallet.publicKey,
        ...({ priceAccount } as any),
        systemProgram: SystemProgram.programId,
      })
      .transaction();

    return initUserStateTx;
  } catch (error: unknown) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || (error as Error).message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};

export const updatePrice = async (
  connection: Connection,
  wallet: any,
  amount: number
) => {
  try {
    const program = programInstance(connection, wallet);

    const [priceAccount] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(PRICEACCOUNT)],
      program.programId
    );
    const amountinpower = Math.round(amount * 1e8);
    let amountBN = new BN(amountinpower.toString()); // Ensure integer input

    const stakeTx = await program.methods
      .updatePrice(amountBN)
      .accounts({
        ...({ priceAccount } as any),
        authority: wallet.publicKey,
      })
      .transaction();

    return stakeTx;
  } catch (error: unknown) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || (error as Error).message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};

export const initializeorUpdatePrice = async (
  connection: Connection,
  wallet: any,
  amount: number
) => {
  try {
    const program = programInstance(connection, wallet);
    let transaction = new Transaction();
    if (await checkPricePdaAccountExistence(connection, program.programId)) {
      const tx = await updatePrice(connection, wallet, amount);
      if (tx) {
        transaction.add(tx);
      }

      transaction.feePayer = wallet.publicKey;
      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;

      // Sign the transaction - THIS IS THE KEY ADDITION
      if (wallet.signTransaction) {
        transaction = await wallet.signTransaction(transaction);
      } else {
        throw new Error("Wallet doesn't support transaction signing");
      }

      // Serialize and send the signed transaction
      const serializedTransaction = transaction.serialize();
      const tran = await connection.sendRawTransaction(serializedTransaction, {
        skipPreflight: false,
        preflightCommitment: "confirmed",
      });

      // Wait for confirmation
      await connection.confirmTransaction(tran, "confirmed");
      enqueueSnackbar("Token staked successfully", {
        variant: "success",
        autoHideDuration: 6000,
      });

      return true;
    } else {
      let tx = await initializePrice(connection, wallet, amount);
      if (tx) {
        transaction.add(tx);
        transaction.feePayer = wallet.publicKey;
        transaction.recentBlockhash = (
          await connection.getLatestBlockhash()
        ).blockhash;
      }
      // Sign the transaction - THIS IS THE KEY ADDITION
      if (wallet.signTransaction) {
        transaction = await wallet.signTransaction(transaction);
      } else {
        throw new Error("Wallet doesn't support transaction signing");
      }

      // Serialize and send the signed transaction
      const serializedTransaction = transaction.serialize();
      const tran = await connection.sendRawTransaction(serializedTransaction, {
        skipPreflight: false,
        preflightCommitment: "confirmed",
      });

      // Wait for confirmation
      await connection.confirmTransaction(tran, "confirmed");
      enqueueSnackbar("Token staked successfully", {
        variant: "success",
        autoHideDuration: 6000,
      });

      return true;
    }
  } catch (error: unknown) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || (error as Error).message, {
      variant: "error",
      autoHideDuration: 6000,
    });

    return false;
  }
};

export const getTokenPrice = async (connection: Connection, wallet: any) => {
  try {
    const program = programInstance(connection, wallet);
    const [priceAccount] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(PRICEACCOUNT)],
      program.programId
    );
    const price = await program.account.priceAccount.fetch(priceAccount);
    console.log(price.price.toNumber() / 1e8);
    return {
      price: price.price.toNumber() / 1e8,
      lastUpdate: price.lastUpdateTimestamp.toNumber(),
    };
  } catch (error) {
    return { price: 0, lastUpdate: 0 };
  }
};

export const changePriceAccountOwner = async (
  connection: Connection,
  wallet: any,
  ownerAddress: string
) => {
  try {
    const program = programInstance(connection, wallet);
    const [priceAccount] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(PRICEACCOUNT)],
      program.programId
    );

    await program.methods
      .updatePriceAccountOwner(new PublicKey(ownerAddress))
      .accounts({
        ...({ authority: wallet.publicKey } as any),
        priceAccount,
      })
      .signers([])
      .rpc();

    enqueueSnackbar("Owner update successfully", {
      variant: "success",
      autoHideDuration: 6000,
    });
    return true;
  } catch (error: unknown) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || (error as Error).message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};
