import moment from "moment";
import { getSolanaExplorerLink, Transaction } from "src/util/consts"

const shortenAddress = (address: string) => {
    if (!address) return '';
    const start = address.substring(0, 6);
    const end = address.substring(address.length - 5);
    return `${start}...${end}`;
};

interface HistoryTableProps {
    History: Array<Transaction>;
    handlePageChange: (page: number) => void; // Function to handle page changes
    currentPage: number; // Current page number
    totalPages: number; // Total number of pages
}

const HistoryTable = ({ History, handlePageChange, currentPage, totalPages }: HistoryTableProps) => {
    return (
        <div className="stake-container main-padding-area stake-table">
            <div className="container-width">
                <div>
                    <div className="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Transaction Type</th>
                                    <th>Address</th>
                                    <th>Token Amount</th>
                                    <th>Transaction Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {History.length > 0 ? (
                                    History.map((history: Transaction, index: number) => (
                                        <tr key={index}>
                                            <td>{history.eventName.replace('Event', '')}</td>

                                            <td>
                                                <a
                                                    className="solana-link"
                                                    href={getSolanaExplorerLink(history?.address, "mainnet")}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {shortenAddress(history?.address)}

                                                </a>
                                            </td>
                                            <td>{history.amount}</td>
                                            <td>{moment(history.timestamp).fromNow()}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5} className="text-center">
                                            No staker data available.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="pagination-container">
                <button
                    className="pagination-button"

                    onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                </button>
                <span className="pagination-text"> Page {currentPage} of {totalPages} </span>
                <button
                    className="pagination-button"

                    onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        </div>
    )
}

export default HistoryTable
