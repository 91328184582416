import { PublicKey } from "@solana/web3.js";
import { BN } from "@coral-xyz/anchor";

const TOKEN_SEED = "token";
const BASE_SEED_USER_STATE = "user";
const STAKE_TOKEN_SEED = "stake_info";
const GLOBAL_CONFIG = "global-config";
const REWARD_VAULT_SEED = "reward_vault";
const STAKE_TREASURY_VAULTS_AUTHORITY = "stake_authority";
const REWARD_TREASURY_VAULTS_AUTHORITY = "reward_authority";
const PRICEACCOUNT = "price_account";

const mintAddress = new PublicKey(
  "Gdck9KXSSiMMhNyjUjo4sVT1GDzeZnZP2yse9jhax3GR"
);

interface Tier {
  tokenRequirement1: number;
  tokenRequirement2: number;
  commitmentTime: number;
  clientCommission: number;
  apy: number;
  accessToPremiumNodes: boolean;
  referralProgram: number;
}
interface StakerProfileData {
  stakeTime: BN; // BigNumber (BN)
  lockDuration: number; // number (u32)
  claimedToken: BN; // BigNumber (BN)
  userAddress: PublicKey; // Solana PublicKey type
  availableTier: any; // Depending on what 'availableTier' is, you might want to replace `any` with a specific type
  updatedTimestamp: BN; // BigNumber (BN)
  totalStakedToken: BN; // BigNumber (BN)
  currentStakedToken: BN; // BigNumber (BN)
  claimedRewards: BN; // BigNumber (BN)
}
interface IdlError {
  code: number;
  name: string;
  msg: string;
  message?: string; // Optional message field
}
interface Transaction {
  // Define properties based on the structure of a single transaction
  id: string;
  eventName: string;
  amount: number;
  timestamp: string;
  address: string;
  status: string;
  // Add other fields based on your response structure
}
interface Tiers {
  name: string;
  tokenRequirement1: number;
  tokenRequirement2: number;
  commitmentTime: number;
  clientCommission: number;
  accessToPremiumNodes: boolean;
  referralProgram: number;
  apy: number;
}
interface StakerRecordsResponse {
  status: boolean;
  transactions: Transaction[];
  recordsLength: number;
  totalRecords: number;
  currentPage: number;
  totalPages: number;
  message: string;
}
const generateTierNames = (numTiers: number) =>
  Array.from({ length: numTiers }, (_, index) => `Tier ${index}`);

// Generate tier names for 20 tiers
const tierNames = generateTierNames(20);
const COINGECKO_API_URL = "https://api.coingecko.com/api/v3/simple/price";

const getSolanaExplorerLink = (address: string, cluster: string = "mainnet") =>
  `https://explorer.solana.com/address/${address}?cluster=${cluster}`;

const createTiers = (numTiers: number) => {
  const tiers = [];
  for (let i = 0; i < numTiers; i++) {
    tiers.push({
      tokenRequirement1: i === 0 ? 0 : 10 * Math.pow(2, i - 1), // Exponential increase
      tokenRequirement2: i === 0 ? 0 : 20 * Math.pow(2, i - 1), // Exponential increase
      commitmentTime: i === 0 ? 0 : 30 * i, // Incremental commitment time (e.g., in days)
      clientCommission: i === 0 ? 50 : 30, // Static for tiers > 0
      apy: i === 0 ? 0 : Math.floor(Math.random() * 100), // Generates APY between 0-9900
      accessToPremiumNodes: i >= 1, // Premium access from Tier 1 onward
      referralProgram: i === 0 ? 0 : i < 3 ? 1 : 2, // Enhanced rates for higher tiers
    });
  }
  return tiers;
};

// Generate 20 tiers
const initialTierData = createTiers(20);

export {
  mintAddress,
  initialTierData,
  getSolanaExplorerLink,
  GLOBAL_CONFIG,
  tierNames,
  STAKE_TREASURY_VAULTS_AUTHORITY,
  BASE_SEED_USER_STATE,
  STAKE_TOKEN_SEED,
  TOKEN_SEED,
  REWARD_VAULT_SEED,
  REWARD_TREASURY_VAULTS_AUTHORITY,
  COINGECKO_API_URL,
  PRICEACCOUNT,
};

export const validateTiers = (tiers: any, length: number) => {
  let lastMaxValue = 0;

  // Helper function to compare numbers properly
  const compareNumbers = (a: number, b: number): boolean => {
    return Number(a) >= Number(b);
  };

  // Validate each tier's token requirements are valid and non-degenerate
  for (let i = 0; i < length; i++) {
    const tier = tiers[i];
    const tokenReq1 = Number(tier.tokenRequirement1);
    const tokenReq2 = Number(tier.tokenRequirement2);

    // Skip validation for Tier 0
    if (i === 0) {
      lastMaxValue = tokenReq2;
      continue;
    }

    // Check if the range is valid (first number should be less than second)
    if (tokenReq1 >= tokenReq2) {
      return `In tier ${i}, the range must be valid and non-degenerate: [${tokenReq1}, ${tokenReq2}].`;
    }

    // Check if current tier's minimum is greater than the last maximum
    if (!compareNumbers(tokenReq1, lastMaxValue)) {
      return `Tier ${i}'s minimum value [${tokenReq1}] must be greater than previous tier's maximum value [${lastMaxValue}].`;
    }

    // Update the last maximum value for the next iteration
    lastMaxValue = tokenReq2;
  }

  return null; // No validation errors
};
export type {
  Tier,
  IdlError,
  StakerProfileData,
  StakerRecordsResponse,
  Transaction,
  Tiers,
};
