import StakerHistory from "./components/StakerHistory";
import ChangeVaultOwner from "./components/changeOwner";
import Stake from "./components/Stake";
import Header from "./components/Header";
import Tabs from "./components/Form/tabs";
import { Route, Routes } from "react-router";
import { SnackbarProvider } from "notistack";
import TierTable from "./components/Vault";
import HomePage from "./components/Home";
import AddReward from "./components/addreward";
import ClaimReward from "./components/claimreward"
import TokenPrice from "./components/UpdatePrice";
import ChangePriceOwner from "./components/PriceAccount";
import Transactions from "./components/Transactions";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

const App = () => {
  return (
    <div className="App">
      <div className="wrapper">
        <SnackbarProvider
          iconVariant={{
            success: "✅",
            error: "✖️",
          }}
        />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/change-owner" element={<ChangeVaultOwner />} />
          <Route path="/global-config" element={<Tabs />} />
          <Route path="/stake" element={<Stake />} />
          <Route path="/tiers-details" element={<TierTable />} />
          <Route path="/claim-reward" element={<ClaimReward />} />
          <Route path="/add-reward" element={<AddReward />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/update-token-price" element={<TokenPrice />} />
          <Route path="/change-price-account" element={<ChangePriceOwner />} />

          <Route path="/staker-history" element={<StakerHistory />} />

        </Routes>
      </div>
    </div>
  );
};

export default App;
