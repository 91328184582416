import { Connection, PublicKey } from "@solana/web3.js";
import { AnchorProvider, Program } from "@coral-xyz/anchor";
import { Locking } from "../types/locking";
import Idl from "../IDL/idl.json";

// Define a type for the wallet parameter
export interface Wallet {
  publicKey: PublicKey;
  signTransaction: (transaction: any) => Promise<any>;
  signAllTransactions: (transactions: any[]) => Promise<any[]>;
}

const programInstance = (connection: Connection, wallet: Wallet) => {
  const solNetwork: any =
    "https://staked.helius-rpc.com?api-key=1ab3130c-3399-472e-be9b-4ecc3575ac25";

  let custom_connection = new Connection(solNetwork, {
    commitment: "finalized", // Ensures transaction finality
    confirmTransactionInitialTimeout: 60000, // Set timeout to 60 seconds
  });

  const idlData: any = Idl;
  const provider = new AnchorProvider(custom_connection, wallet, {
    commitment: "finalized",
    preflightCommitment: "finalized",
  });
  const program = new Program(idlData, provider) as Program<Locking>;
  return program;
};

export { programInstance };
