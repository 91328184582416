import Loader from "../Loader/loader";
import { Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import CountdownTimer from "../Counter/Counter";
import { programInstance } from "src/util/program/program";
import { claimRewards } from "src/util/program/claimReward";
import { getStakerProfile } from "../../util/program/staking";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { checkUserPdaAccountExistence } from "src/util/program/validateAccounts";
import { StakerProfileData } from "src/util/consts";

const ClaimReward = () => {
	const [loading, setLoading] = useState(false);
	const [Staked, setStaked] = useState<number>(0);
	const [totalStaked, setTotalStaked] = useState<number>(0);
	const [remainingTime, setRemainingTime] = useState<number>(0);
	const [claimableTokens, setClaimableTokens] = useState<number>(0);

	const wallet = useWallet();
	const { connection } = useConnection();
	const { publicKey } = useWallet();

	const getSTakerProfileResponse = async () => {
		if (!publicKey) {
			setTotalStaked(0);
			setStaked(0);
			setClaimableTokens(0);
			setRemainingTime(0);
			return;
		}
		setLoading(true);
		const res: StakerProfileData | null = await getStakerProfile(connection, wallet);
		const programId = programInstance(connection, { wallet } as any).programId
		await checkUserPdaAccountExistence(connection, publicKey, programId)

		if (res) {
			setTotalStaked(res?.totalStakedToken?.toNumber());
			setStaked(res?.currentStakedToken?.toNumber());
			setClaimableTokens(res?.claimedRewards?.toNumber());
			const time = res?.stakeTime?.toNumber() + res?.lockDuration;
			setRemainingTime(time);
		} else {
			setRemainingTime(0);
		}

		setLoading(false);
	};

	const stakeTokenFunc = async () => {
		if (!publicKey) {
			enqueueSnackbar("Please connect your wallet", {
				variant: 'error', autoHideDuration: 6000
			})
			return
		}
		setLoading(true);

		await claimRewards(connection, wallet);
		getSTakerProfileResponse();
		setLoading(false);
	};
	useEffect(() => {
		getSTakerProfileResponse();
	}, [publicKey]);

	return (
		<>
			{
				loading ?
					(
						<Loader />
					) : (
						<div className="stake-container main-padding-area">
							<div className="container-width">
								<Row>
									<Col lg="5" className="mb-lg-0 mb-sm-4 mb-3">
										<div className="countdown-timer pb-3">
											<div className="countdown-timer-info">
												<h3 className="text-white">Claim your tokens</h3>
												<p className="text-white text-white-light mb-0">Withdraw your staked tokens and end the staking commitment.</p>
											</div>
											<button onClick={stakeTokenFunc} disabled={Staked === 0 ? true : false} className="btn-green-gradient mb-3">
												Claim Token
											</button>
										</div>
									</Col>
									<Col lg="7">
										<div>
											<CountdownTimer
												futureUnixTimestampTitle="Time Remaining"
												futureUnixTimestamp={remainingTime}
												totalStakedTitle="Total Staked Token"
												claimableTokens={claimableTokens}
												totalStaked={totalStaked}
												Staked={Staked}
											/>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					)
			}
		</>
	);
};

export default ClaimReward;