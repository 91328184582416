import { StakerRecordsResponse } from "src/util/consts";
import axiosInstance from "../Handlers";

export const singleStakerRecords = async (
  walletAddress: String,
  page = 1,
  limit = 10
) => {
  try {
    const response = await axiosInstance.get<
      StakerRecordsResponse,
      StakerRecordsResponse
    >(`/transactions/address/${walletAddress}`, {
      params: { page, limit },
    });
    return response;
  } catch (error) {
    console.error("Error fetching vault account:", error);
    return {
      status: false,
      transactions: [],
      recordsLength: 0,
      totalRecords: 0,
      currentPage: 1,
      totalPages: 1,
      message: "Error fetching records",
    };
  }
};

export const allStakerRecords = async (page = 1, limit = 10) => {
  try {
    const response = await axiosInstance.get(`/transactions/`, {
      params: { page, limit },
    });
    return response;
  } catch (error) {
    return { status: false, transactions: [], totalPages: 1 };
  }
};
