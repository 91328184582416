import {
  getStakerProfile,
  initializeUserStateAndStakeToken,
} from "../../util/program/staking";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useState, useEffect, useCallback } from "react";
import CountdownTimer from "../Counter/Counter";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import Loader from "../Loader/loader";
import { fetchTokenBalance } from "src/util/program/functions";
import { singleStakerRecords } from "src/util/API/Staking/staking";
import HistoryTable from "../Events/Table";
import { StakerProfileData, Transaction } from "src/util/consts";

const Stake = () => {

  const [amount, setAmount] = useState<number>(0);
  const [Staked, setStaked] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalStaked, setTotalStaked] = useState<number>(0);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [claimableTokens, setClaimableTokens] = useState<number>(0);
  const [availableToken, setAvailableToken] = useState(0);
  const [userHistory, setUserHistory] = useState<Array<Transaction>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const wallet = useWallet();
  const { publicKey } = useWallet();
  const { connection } = useConnection();

  const getSTakerProfileResponse = useCallback(async () => {
    if (!publicKey?.toBase58()) {
      setTotalStaked(0);
      setStaked(0);
      setClaimableTokens(0);
      setRemainingTime(0);
      return;
    }
    setLoading(true);
    const res: StakerProfileData | null = await getStakerProfile(connection, wallet);
    if (res) {
      setTotalStaked(res?.totalStakedToken?.toNumber());
      setStaked(res?.currentStakedToken?.toNumber());
      setClaimableTokens(res?.claimedRewards?.toNumber());
      const time = res?.stakeTime?.toNumber() + res?.lockDuration;
      setRemainingTime(time);
    } else {
      setRemainingTime(0);
    }
    const balance = await fetchTokenBalance(publicKey, connection);
    setAvailableToken(balance);
    setLoading(false);
  }, [publicKey?.toBase58(), connection]);

  const stakeTokenFunc = async () => {
    if (!publicKey) {
      enqueueSnackbar("Please connect your wallet", {
        variant: "error",
        autoHideDuration: 6000,
      });
      return;
    }

    if (amount < 0) {
      enqueueSnackbar("Amount must be greater than zero", {
        variant: "error",
        autoHideDuration: 6000,
      });
      return;
    }

    if (amount > availableToken) {
      enqueueSnackbar(
        `You don't have enough tokens. Available: ${availableToken.toFixed(2)}`,
        {
          variant: "error",
          autoHideDuration: 6000,
        }
      );
      return;
    }

    setLoading(true);

    await initializeUserStateAndStakeToken(
      connection,
      wallet,
      amount,
    );
    getSTakerProfileResponse();
    fetchTransactions()
    setLoading(false);
    setAmount(0)
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Allow only numbers and enforce min value dynamically
    if (/^\d*$/.test(value)) {
      setAmount(Number(value.toString().replace(/^0+/, '')));
    } else {
      setAmount(Number(value))
    }
  };
  useEffect(() => {
    getSTakerProfileResponse();
  }, [publicKey?.toBase58(), connection]);

  const fetchTransactions = async (page = 1, limit = 10) => {
    if (!wallet.publicKey) {
      return
    }
    setLoading(true);
    try {
      const response = await singleStakerRecords(wallet.publicKey?.toBase58(), page, limit);
      console.log(response, "responseresponse")
      if (response.status)
        setUserHistory(response.transactions);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="stake-container main-padding-area">
          <div className="container-width">
            <Row>
              <Col lg="5" className="mb-lg-0 mb-sm-4 mb-3">
                <div className="countdown-timer pb-3">
                  <div className="countdown-timer-info">
                    <h3 className="text-white">Stake Your Tokens</h3>
                    <p className="text-white text-white-light mb-0">
                      Lock your tokens to earn rewards and support the network.
                    </p>
                  </div>
                  <input
                    type="number"
                    value={amount + ""}
                    onChange={(e: any) => setAmount(e.target.value)}
                    placeholder="Enter amount"
                    className="stake-input mb-3"
                    min={0}
                  />
                  <div className="d-flex justify-content-between align-items-center p-3  rounded">
                    <p className="text-white mb-0">
                      Token balance: {availableToken.toLocaleString("en-US")}
                    </p>

                    <button
                      className="btn btn-primary btn-sm max-btn"
                      onClick={() => setAmount(availableToken)}
                    >
                      Max
                    </button>
                  </div>

                  <button
                    disabled={Staked !== 0 ? true : false}
                    onClick={stakeTokenFunc}
                    className="btn-green-gradient"
                  >
                    Stake Token
                  </button>
                </div>
              </Col>
              <Col lg="7">
                <div>
                  <CountdownTimer
                    futureUnixTimestampTitle="Time Remaining"
                    futureUnixTimestamp={remainingTime}
                    totalStakedTitle="Total Staked"
                    claimableTokens={claimableTokens}
                    totalStaked={totalStaked}
                    Staked={Staked}
                  />
                </div>
              </Col>
            </Row>
            {userHistory && userHistory.length && <HistoryTable History={userHistory} handlePageChange={handlePageChange}
              currentPage={currentPage} totalPages={totalPages} />}
          </div>
        </div>
      )}
    </>
  );
};

export default Stake;
