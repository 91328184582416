import { updateRemainingTime } from 'src/util/program/functions';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { getAllStakerData } from 'src/util/program/staking';
import { useCallback, useEffect, useState } from 'react';
import Loader from "../Loader/loader";
import { getSolanaExplorerLink } from 'src/util/consts';
import { getTokenPrice } from 'src/util/program/price';

const StakerHistory = () => {
    const [loading, setLoading] = useState(false);
    const [wifPrice, setWifPrice] = useState<number>(0);
    const [stakerData, setStakerData] = useState<any[]>([]);
    const [remainingTimes, setRemainingTimes] = useState<any[]>([]); // Store remaining times
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const { connection } = useConnection();
    const wallet = useWallet();
    const { publicKey } = useWallet();

    const fetchPrice = async () => {
        const { price } = await getTokenPrice(connection, wallet);
        setWifPrice(price);
    };

    const getStakerProfileResponse = useCallback(async () => {
        await fetchPrice();
        // if (!connection || !publicKey) {
        //     setError('Connection or wallet is not initialized.');
        //     return;
        // }

        setLoading(true);
        setError(null);

        try {
            const res: any = await getAllStakerData(connection, wallet);

            if (Array.isArray(res)) {
                setStakerData(res);
                // Initialize remaining times when data is fetched
                setRemainingTimes(res.map((tier) => updateRemainingTime(tier?.account?.stakeTime?.toNumber() + tier?.account?.endLockTime)));
            } else {
                setError('No staker record found');
                setStakerData([]);
                setRemainingTimes([]);
            }
        } catch (err: any) {
            console.error('Error fetching staker data:', err);
            setStakerData([]);
            setRemainingTimes([]);
        } finally {
            setLoading(false);
        }
    }, [connection, wallet, publicKey]);

    useEffect(() => {

        // if (publicKey) {
        getStakerProfileResponse();
        // }
    }, [publicKey?.toBase58()]);

    useEffect(() => {
        // Set interval to update remaining time every second
        const intervalId = setInterval(() => {
            setRemainingTimes((prevTimes) =>
                prevTimes.map((time, index) => updateRemainingTime(stakerData[index]?.account?.stakeTime?.toNumber() + stakerData[index]?.account?.endLockTime))
            );
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [stakerData]); // Re-run when stakerData changes

    const paginatedData = stakerData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(stakerData.length / itemsPerPage);

    const shortenAddress = (address: string) => {
        if (!address) return '';
        const start = address.substring(0, 6);
        const end = address.substring(address.length - 5);
        return `${start}...${end}`;
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="stake-container main-padding-area stake-table">
                    <div className="container-width">
                        <div>
                            <h3 className="text-white mb-4">Staker History</h3>
                            {loading && <p className="text-info">Loading...</p>}
                            {error && <p className="text-danger">{error}</p>}
                            {!loading && !error && (
                                <div className="table-responsive">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Staker Address</th>
                                                <th>Current Tokens Staked</th>
                                                <th>Current Tokens Staked in USD</th>
                                                <th>Current Tier</th>
                                                <th>Remaining Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedData.length > 0 ? (
                                                paginatedData.map((tier: any, index: number) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <a
                                                                className="solana-link"
                                                                href={getSolanaExplorerLink(tier?.account?.identity, "mainnet")}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {shortenAddress(tier?.account?.identity)}

                                                            </a>
                                                        </td>
                                                        <td>{tier?.account?.stakeAmount}</td>
                                                        <td>{(tier?.account?.stakeAmount * wifPrice).toFixed(2)} $</td>
                                                        <td>{tier?.account?.tier.name}</td>
                                                        <td>{remainingTimes[index]}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={5} className="text-center">
                                                        No staker data available.
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="pagination-container">
                                        <button
                                            className="pagination-button"
                                            disabled={currentPage === 1}
                                            onClick={() => setCurrentPage((prev) => prev - 1)}
                                        >
                                            Previous
                                        </button>
                                        <span className="pagination-text">
                                            Page {currentPage} of {totalPages}
                                        </span>
                                        <button
                                            className="pagination-button"
                                            disabled={currentPage === totalPages}
                                            onClick={() => setCurrentPage((prev) => prev + 1)}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default StakerHistory;
