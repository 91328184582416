import {
  Connection,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import { programInstance } from "./program";
import { BN, utils } from "@coral-xyz/anchor";
import {
  GLOBAL_CONFIG,
  mintAddress,
  REWARD_TREASURY_VAULTS_AUTHORITY,
  REWARD_VAULT_SEED,
} from "../consts";
import {
  getAssociatedTokenAddressSync,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  checkRewardPdaAccountExistence,
  parseErrorFromIDL,
} from "./validateAccounts";
import { enqueueSnackbar } from "notistack";

export const initailizeReward = async (connection: Connection, wallet: any) => {
  try {
    const program = programInstance(connection, wallet);
    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );
    const [rewardVault] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(REWARD_VAULT_SEED), globalConfig.toBuffer()],
      program.programId
    );
    const [rewardTreasury] = PublicKey.findProgramAddressSync(
      [
        utils.bytes.utf8.encode(REWARD_TREASURY_VAULTS_AUTHORITY),
        mintAddress.toBuffer(),
      ],
      program.programId
    );
    const txReward = await program.methods
      .initializeRewards()
      .accounts({
        ...({ globalAdmin: wallet.publicKey } as any),
        globalConfig,
        rewardVault,
        rewardTreasury,
        tokenMint: mintAddress,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
      })
      .transaction();
    return txReward;
  } catch (error: unknown) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || (error as Error).message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};

export const AddReward = async (
  connection: Connection,
  wallet: any,
  amount: number
) => {
  try {
    const program = programInstance(connection, wallet);
    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );
    const [rewardVault] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(REWARD_VAULT_SEED), globalConfig.toBuffer()],
      program.programId
    );
    const [rewardTreasury] = PublicKey.findProgramAddressSync(
      [
        utils.bytes.utf8.encode(REWARD_TREASURY_VAULTS_AUTHORITY),
        mintAddress.toBuffer(),
      ],
      program.programId
    );
    const associatedTokenAccountB = getAssociatedTokenAddressSync(
      mintAddress,
      wallet.publicKey,
      true
    );
    const amountinpower = Math.round(amount * 1e8);
    let amountBN = new BN(amountinpower.toString());
    const txReward = await program.methods
      .addRewards(amountBN)
      .accounts({
        ...({ globalAdmin: wallet.publicKey } as any),
        globalConfig,
        rewardVault,
        rewardTreasury,
        rewardMint: mintAddress,
        tokenProgram: TOKEN_PROGRAM_ID,
        payerRewardTokenAta: associatedTokenAccountB,
      })
      .transaction();
    return txReward;
  } catch (error: unknown) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || (error as Error).message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};

export const initialAndAddReward = async (
  connection: Connection,
  wallet: any,
  amount: number
) => {
  try {
    const program = programInstance(connection, wallet);
    let transaction = new Transaction();
    if (await checkRewardPdaAccountExistence(connection, program.programId)) {
      const tx = await AddReward(connection, wallet, amount);
      if (tx) {
        transaction.add(tx);
      }

      transaction.feePayer = wallet.publicKey;
      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;

      // Sign the transaction - THIS IS THE KEY ADDITION
      if (wallet.signTransaction) {
        transaction = await wallet.signTransaction(transaction);
      } else {
        throw new Error("Wallet doesn't support transaction signing");
      }

      // Serialize and send the signed transaction
      const serializedTransaction = transaction.serialize();
      const tran = await connection.sendRawTransaction(serializedTransaction, {
        skipPreflight: false,
        preflightCommitment: "confirmed",
      });

      // Wait for confirmation
      await connection.confirmTransaction(tran, "confirmed");
      enqueueSnackbar("Reward has been add successfully", {
        variant: "success",
        autoHideDuration: 6000,
      });

      return true;
    } else {
      let tx = await initailizeReward(connection, wallet);

      if (tx) {
        transaction.add(tx);
        transaction.feePayer = wallet.publicKey;
        transaction.recentBlockhash = (
          await connection.getLatestBlockhash()
        ).blockhash;
      }
      let tx1 = await AddReward(connection, wallet, amount);
      if (tx1) {
        transaction.add(tx1);
        transaction.feePayer = wallet.publicKey;
        transaction.recentBlockhash = (
          await connection.getLatestBlockhash()
        ).blockhash;
      }
      transaction.feePayer = wallet.publicKey;
      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;

      // Sign the transaction - THIS IS THE KEY ADDITION
      if (wallet.signTransaction) {
        transaction = await wallet.signTransaction(transaction);
      } else {
        throw new Error("Wallet doesn't support transaction signing");
      }

      // Serialize and send the signed transaction
      const serializedTransaction = transaction.serialize();
      const tran = await connection.sendRawTransaction(serializedTransaction, {
        skipPreflight: false,
        preflightCommitment: "confirmed",
      });

      // Wait for confirmation
      await connection.confirmTransaction(tran, "confirmed");
      enqueueSnackbar("Reward has been add successfully", {
        variant: "success",
        autoHideDuration: 6000,
      });

      return true;
    }
  } catch (error: unknown) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || (error as Error).message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};
