import HistoryTable from '../Events/Table';
import { useEffect, useState } from 'react';
import { allStakerRecords } from '../../util/API/Staking/staking';
import Loader from "../Loader/loader";

const Transactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    const fetchTransactions = async (page = 1, limit = 10) => {
        setLoading(true);
        try {
            const response: any = await allStakerRecords(page, limit);
            console.log(response, "responseresponse")
            if (response.status)
                setTransactions(response.transactions);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error("Error fetching transactions:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactions(currentPage);
    }, [currentPage]);

    const handlePageChange = (newPage: number) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <ul>
                        {
                            transactions.length > 0 && transactions
                            &&
                            <HistoryTable
                                History={transactions} handlePageChange={handlePageChange}
                                currentPage={currentPage} totalPages={totalPages}
                            />
                        }
                    </ul>

                </>
            )}
        </div>
    );
};

export default Transactions;