import { Connection, PublicKey } from "@solana/web3.js";
import { BN, utils } from "@coral-xyz/anchor";
import {
  BASE_SEED_USER_STATE,
  GLOBAL_CONFIG,
  IdlError,
  PRICEACCOUNT,
  REWARD_VAULT_SEED,
  Tier,
  tierNames,
  Tiers,
} from "../consts";
import Idl from "../IDL/idl.json";

export const checkUserPdaAccountExistence = async (
  connection: Connection,
  wallet: PublicKey,
  programId: PublicKey
): Promise<boolean> => {
  try {
    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      programId
    );
    const [userState] = PublicKey.findProgramAddressSync(
      [
        utils.bytes.utf8.encode(BASE_SEED_USER_STATE),
        globalConfig.toBuffer(),
        wallet.toBuffer(),
      ],
      programId
    );
    const accountInfo = await connection.getAccountInfo(userState);
    if (accountInfo === null) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const checkRewardPdaAccountExistence = async (
  connection: Connection,
  programId: PublicKey
): Promise<boolean> => {
  try {
    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      programId
    );
    const [RewardStake] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(REWARD_VAULT_SEED), globalConfig.toBuffer()],
      programId
    );
    const accountInfo = await connection.getAccountInfo(RewardStake);
    if (accountInfo === null) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const checkPricePdaAccountExistence = async (
  connection: Connection,
  programId: PublicKey
): Promise<boolean> => {
  try {
    const [priceAccount] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(PRICEACCOUNT)],
      programId
    );
    const accountInfo = await connection.getAccountInfo(priceAccount);
    if (accountInfo === null) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const parseErrorFromIDL = async (error: any) => {
  try {
    // Check for user-rejected transaction

    console.log(error, "error");
    if (
      error.code === 4001 ||
      error.message?.toLowerCase().includes("user rejected")
    ) {
      return {
        code: "USER_REJECTED",
        message: error.message,
      };
    }

    // If no logs are available
    if (!error.logs || error.logs.length === 0) {
      return { code: "UNKNOWN", message: error.message };
    }

    // Scan logs for specific messages or patterns
    for (const log of error.logs) {
      // Check for validation error patterns
      if (log.includes("Validation error:")) {
        const validationMessage = log.split("Validation error:")[1]?.trim();
        return {
          code: "VALIDATION_ERROR",
          message: `${validationMessage}`,
        };
      }
      if (log.includes("global_config")) {
        return {
          code: "ADMIN_ERROR",
          message: `Only the admin wallet has access to update these functions`,
        };
      }
      // Check for custom program errors
      if (log.includes("custom program error")) {
        const errorCodeHex = log.split("custom program error: ")[1]?.trim();
        const errorCodeDecimal = parseInt(errorCodeHex, 16);

        // Match the error code with the IDL error definitions
        const matchedError = Idl.errors?.find(
          (err: IdlError) => err.code === errorCodeDecimal
        );

        if (matchedError) {
          return { code: errorCodeHex, message: matchedError.msg };
        }

        return {
          code: errorCodeHex,
          message: "Something went wrong.",
        };
      }
    }

    return { code: "NO_ERROR", message: "No custom program error found." };
  } catch (parseError) {
    console.error("Error while parsing the error logs:", parseError);
    return {
      code: "PARSE_ERROR",
      message: "Failed to parse the error logs.",
    };
  }
};

export const convertCommitmentTime = (commitmentTime: number) => {
  return commitmentTime * 24 * 60 * 60;
};

export const convertupdateIntoTiers = (data: any): Tiers => {
  return {
    name: data.name,
    tokenRequirement1: data.tokenRequirements[0].toNumber() / 1000,
    tokenRequirement2: data.tokenRequirements[1].toNumber() / 1000,
    commitmentTime: data.commitmentTime.toNumber() / (24 * 60 * 60),
    clientCommission: data.clientCommission,
    accessToPremiumNodes: data.accessToPremiumNodes,
    referralProgram: data.referralProgram,
    apy: data.apy ? data.apy / 100 : 0,
  };
};

export const convertToBN = (data: Tier, index: number) => {
  return {
    name: tierNames[index],
    tokenRequirements: [
      new BN(data?.tokenRequirement1 * 1000),
      new BN(data?.tokenRequirement2 * 1000),
    ],
    commitmentTime: new BN(convertCommitmentTime(data.commitmentTime)),
    clientCommission: data.clientCommission,
    accessToPremiumNodes: data.accessToPremiumNodes,
    referralProgram: data.referralProgram,
    apy: data.apy * 100,
  };
};

export const parseError = (errorText: string) => {
  const parts = errorText?.split(". ");

  const fileLine = parts[0]?.split(":");
  const errorCode = parts[1]?.split(": ")[1];
  const errorNumber = parts[2]?.split(": ")[1];
  const errorMessage = parts[3]?.split(": ")[1];
  return {
    file: fileLine[0]?.split("/").pop(),
    line: fileLine[1],
    errorCode: errorCode,
    errorNumber: errorNumber,
    errorMessage: errorMessage,
    transactionHash: null,
  };
};
