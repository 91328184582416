import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getTokenPrice } from 'src/util/program/price';

const TokenPrice = () => {
    const [wifPrice, setWifPrice] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const wallet = useWallet();
    const { connection } = useConnection();
    useEffect(() => {
        const fetchPrice = async () => {
            const { price, lastUpdate } = await getTokenPrice(connection, wallet);
            setWifPrice(price);
            setLoading(false);
        };
        fetchPrice();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Row>
                <Col lg="4" className='mb-lg-0 mb-4'>
                    <div className='countdown-timer'>
                        <div className='countdown-timer-info'>
                            <h2 className='text-white'>Token Price</h2>
                            {wifPrice !== null ? (
                                <p className="text-white text-white-light mb-0">The current market value of the Hivello token</p>
                            ) : (
                                <p className="text-white text-white-light mb-0">Failed to fetch the price.</p>
                            )}
                        </div>
                        <span className='text-green me-2'>${wifPrice}</span>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default TokenPrice;
