import {
  ComputeBudgetProgram,
  Connection,
  PublicKey,
  SystemProgram,
} from "@solana/web3.js";
import { programInstance } from "./program";
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import { utils } from "@coral-xyz/anchor";
import { enqueueSnackbar } from "notistack";
import axios from "axios";
import moment from "moment";
import { getAssociatedTokenAddressSync } from "@solana/spl-token";
import {
  BASE_SEED_USER_STATE,
  COINGECKO_API_URL,
  GLOBAL_CONFIG,
  mintAddress,
  REWARD_VAULT_SEED,
  STAKE_TREASURY_VAULTS_AUTHORITY,
} from "../consts";
import {
  convertToBN,
  convertupdateIntoTiers,
  parseErrorFromIDL,
} from "./validateAccounts";

const priorityFee = 100_000; // Adjust the fee based on network conditions

export const initailizeVault = async (
  connection: Connection,
  wallet: any,
  tier: any
) => {
  try {
    const program = programInstance(connection, wallet);

    const [stakeTreasury] = PublicKey.findProgramAddressSync(
      [
        utils.bytes.utf8.encode(STAKE_TREASURY_VAULTS_AUTHORITY),
        mintAddress.toBuffer(),
      ],
      program.programId
    );

    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );
    const convertedTier1 = tier
      .slice(0, 10) // Includes indices 0 to 9 (10 items total)
      .map((data: any, index: number) => convertToBN(data, index));
    const convertedTier2 = tier
      .slice(10, 20) // Includes indices 10 to 19 (10 items total)
      .map((data: any, index: number) => convertToBN(data, index + 10));
    await program.methods
      .initializeGlobalConfig(convertedTier1, convertedTier2)
      .accounts({
        ...({ stakeTreasury } as any),
        globalConfig,
        globalAdmin: wallet.publicKey,
        tokenMint: mintAddress,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
      })
      .preInstructions([
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: priorityFee,
        }),
      ])
      .signers([])
      .rpc();
    enqueueSnackbar("Account initailize successfully", {
      variant: "success",
      autoHideDuration: 6000,
    });
    return true;
  } catch (error: unknown) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || (error as Error).message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};

export const updateVault = async (
  connection: Connection,
  wallet: any,
  tier: any
) => {
  try {
    const program = programInstance(connection, wallet);
    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );
    const convertedTier1 = tier
      .slice(0, 10) // Includes indices 0 to 9 (10 items total)
      .map((data: any, index: number) => convertToBN(data, index));

    const convertedTier2 = tier
      .slice(10, 20) // Includes indices 10 to 19 (10 items total)
      .map((data: any, index: number) => convertToBN(data, index + 10));

    await program.methods
      .updateTiers(convertedTier1, convertedTier2)
      .accounts({
        globalConfig,
        ...({ globalAdmin: wallet.publicKey } as any),
      })
      .signers([])
      .rpc();
    enqueueSnackbar("Tiers Data updated successfully", {
      variant: "success",
      autoHideDuration: 6000,
    });
    return true;
  } catch (error: unknown) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || (error as Error).message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};

export const closeStakerProfile = async (
  connection: Connection,
  wallet: any
) => {
  try {
    const program: any = programInstance(connection, wallet);
    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );
    const [userState] = PublicKey.findProgramAddressSync(
      [
        utils.bytes.utf8.encode(BASE_SEED_USER_STATE),
        globalConfig.toBuffer(),
        wallet.publicKey.toBuffer(),
      ],
      program.programId
    );
    await program.methods
      .closeStaker()
      .accounts({
        payer: wallet.publicKey,
        userState: userState,
        rewardMint: mintAddress,
        globalConfig,
      })
      .signers([])
      .rpc();
  } catch (error: unknown) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || (error as Error).message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};

export const changeOwner = async (
  connection: Connection,
  wallet: any,
  ownerAddress: string
) => {
  try {
    const program = programInstance(connection, wallet);
    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );

    await program.methods
      .updateGlobalAdmin(new PublicKey(ownerAddress))
      .accounts({
        ...({ globalAdmin: wallet.publicKey } as any),
        globalConfig,
      })
      .signers([])
      .rpc();

    enqueueSnackbar("Owner update successfully", {
      variant: "success",
      autoHideDuration: 6000,
    });
    return true;
  } catch (error: unknown) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || (error as Error).message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};

export const getVaultData = async (connection: Connection, wallet: any) => {
  try {
    const program = programInstance(connection, wallet);
    const [vault] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );
    const data = await program.account.globalConfig.fetch(vault);
    const tiers = data.tiers.map(convertupdateIntoTiers);
    return tiers;
  } catch (error) {
    return null;
  }
};
export const getVaultAccountData = async (
  connection: Connection,
  wallet: any
) => {
  try {
    const program = programInstance(connection, wallet);
    const [vault] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );
    const data = await program.account.globalConfig.fetch(vault);
    const date = new Date(data.lastUpdated.toNumber() * 1000);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return {
      stakerCount: data.totalStaker.toNumber(),
      totalStaked: data.totalStakedHistory.toNumber() / 1e8,
      lastTime: formattedDate,
      isPuased: data.isApyEnabled,
      earlyUnstakeEnable: data.earlyUnstakeEnable,
      currentlyStakedToken: data.totalUnstaked.toNumber() / 1e8,
      totalClaimedRewards:
        (data.totalStakedHistory.toNumber() -
          data?.totalUnstaked.toNumber() +
          data?.totalClaimedRewards.toNumber()) /
        1e8,
    };
  } catch (error) {
    return [];
  }
};

export const getRewardInfo = async (connection: Connection, wallet: any) => {
  try {
    const program = programInstance(connection, wallet);

    // Derive globalConfig and rewardVault PDA
    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );
    const [rewardVault] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(REWARD_VAULT_SEED), globalConfig.toBuffer()],
      program.programId
    );

    // Fetch reward info from the rewardVault
    let _rewardInfo: any = await program.account.rewardInfo.fetch(rewardVault);

    // Fetch globalConfig to get totalClaimedRewards
    const globalConfigData = await program.account.globalConfig.fetch(
      globalConfig
    );
    const totalClaimedReward =
      globalConfigData?.totalClaimedRewards || BigInt(0);
    // Add totalClaimedReward to reward info
    _rewardInfo = {
      ..._rewardInfo,
      totalClaimedReward: totalClaimedReward.toString() / 1e8, // Convert BigInt to string
    };

    // Return the updated reward info
    return _rewardInfo;
  } catch (error) {
    return [];
  }
};

export const getOwnerAddress = async (connection: Connection, wallet: any) => {
  try {
    const program = programInstance(connection, wallet);
    const [vault] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );
    const data = await program.account.globalConfig.fetch(vault);
    return data.globalAdmin.toBase58();
  } catch (error) {
    return false;
  }
};

export const getTokenPrice = async () => {
  try {
    const response = await axios.get(
      `${COINGECKO_API_URL}?ids=dogwifcoin&vs_currencies=usd`
    );
    return response.data["dogwifcoin"].usd;
  } catch (error) {
    console.error("Error fetching token price:", error);
    return null;
  }
};

export const updateRemainingTime = (futureUnixTimestamp: number) => {
  if (futureUnixTimestamp === 0) {
    return "Not started Yet";
  }

  const now = moment();
  const futureDate = moment.unix(futureUnixTimestamp);
  const duration = moment.duration(futureDate.diff(now));
  // Check if time has passed or if it's a valid duration
  if (duration.asSeconds() <= 0) {
    return "Time is up!";
  }

  // Calculate and display remaining time
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  // Build the shorter format string
  let timeLeft = "";
  if (days > 0) timeLeft += `${days}d `;
  if (hours > 0) timeLeft += `${hours}h `;
  if (minutes > 0) timeLeft += `${minutes}m `;
  if (seconds > 0) timeLeft += `${seconds}s`;

  return timeLeft.trim();
};

export const fetchTokenBalance = async (
  walletAddress: PublicKey,
  connection: Connection
) => {
  try {
    const associatedTokenAddress = getAssociatedTokenAddressSync(
      mintAddress,
      walletAddress
    );
    const balance = await connection.getTokenAccountBalance(
      associatedTokenAddress
    );
    const formattedBalance =
      Number(balance.value.amount) / 10 ** balance.value.decimals;
    return formattedBalance;
  } catch (error) {
    return 0;
  }
};

export const pausedGlobalConfig = async (
  connection: Connection,
  wallet: any,
  apy: boolean,
  early_unstake: boolean
) => {
  try {
    const program = programInstance(connection, wallet);
    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );
    await program.methods
      .globalConfigSetting(apy, early_unstake)
      .accounts({
        globalConfig,
        ...({ globalAdmin: wallet.publicKey } as any),
      })
      .signers([])
      .rpc();
    enqueueSnackbar("Account setting updated successfully", {
      variant: "success",
      autoHideDuration: 6000,
    });
    return true;
  } catch (error: unknown) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || (error as Error).message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};
